import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatUSD, formatUSDT } from '@shared/lib/numbers';
import { ReactComponent as WalletIcon } from '@icons/custom/wallet-light.svg';
import { ContainerColumn, ContainerRow } from '@components/styled';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import { BodyLargeSemiBold, BodyMediumSemiBold, BodySemiBold, TextEmphasizedMediumBold, } from '@components/styled/Typography';
const Container = styled(ContainerColumn)(props => ({
    display: 'inline-flex',
    width: 'auto',
    height: 'auto',
    minHeight: 150,
    padding: props.theme.spacing_sizes.xm,
    borderRadius: 6,
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'end',
}));
const IconTextStyled = styled(IconText)((props) => ({
    gap: props.theme.spacing_sizes.xs * 1.25,
    '.MuiTypography-root': {
        fontSize: 16,
        color: props.theme.palette.text.primary,
        lineHeight: '19px',
    },
}));
const AvailableUSDTContainer = styled(ContainerRow)(props => ({
    display: 'flex',
    alignItems: 'baseline',
    width: 'auto',
    height: 31,
    gap: props.theme.spacing_sizes.xs * 0.5,
}));
const AvailableUSDTAmount = TextEmphasizedMediumBold;
const AvailableUSDTCurrency = BodyMediumSemiBold;
const TotalBalanceContainer = styled(ContainerRow)((props) => ({
    alignItems: 'baseline',
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const TotalBalanceText = styled(BodySemiBold)(props => ({
    color: props.theme.palette.text.secondary,
}));
const TotalBalanceAmount = BodyLargeSemiBold;
const checkBalanceType = (theme, totalBalance, usdtBalance) => {
    if (usdtBalance && usdtBalance >= 50) {
        return { color: theme.palette.success.main, backgroundColor: '#ECFBF1' };
    }
    if (totalBalance >= 50) {
        return { color: theme.palette.warning.main, backgroundColor: '#FFF9ED' };
    }
    return { color: theme.palette.error.main, backgroundColor: '#F8F9FA' };
};
const ExchangeWalletBalance = ({ totalBalance, usdtBalance = 0, disabled = undefined, className = undefined, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { backgroundColor, color } = checkBalanceType(theme, totalBalance, usdtBalance);
    return (_jsxs(Container, { className: className, style: {
            backgroundColor,
            opacity: disabled ? 0.5 : 1,
        }, children: [_jsx(IconTextStyled, { text: t('overview.exchange_wallets.card.available_balance'), IconComponent: (_jsx(Icon, { IconComponent: WalletIcon, size: 24, color: color })) }), _jsxs(AvailableUSDTContainer, { style: {
                    color,
                }, children: [_jsx(AvailableUSDTAmount, { children: formatUSDT(usdtBalance || 0, { disableCurrencyDisplay: true }) }), _jsx(AvailableUSDTCurrency, { children: t('asset_type.USDT', { ns: 'common' }) })] }), _jsxs(TotalBalanceContainer, { children: [_jsx(TotalBalanceText, { children: t('overview.exchange_wallets.card.total_balance') }), _jsx(TotalBalanceAmount, { children: formatUSD(totalBalance) })] })] }));
};
export default ExchangeWalletBalance;
