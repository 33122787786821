import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerColumn, ContainerRow } from '@components/styled';
import ExchangeIcon from '@shared/ui/icons/ExchangeIcon';
import { BodySmall, BodySmallSemiBold } from '@components/styled/Typography';
const Container = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const ExchangeIconStyled = styled(ExchangeIcon, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    alignItems: 'center',
    opacity: props.disabled ? 0.5 : 1,
}));
const ExchangeName = styled(BodySmallSemiBold, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '24px',
    marginBottom: props.theme.spacing_sizes.xs * 0.25,
    opacity: props.disabled ? 0.5 : 1,
}));
const ExchangeType = styled(BodySmall, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    color: props.theme.palette.text.secondary,
    fontSize: 14,
    textTransform: 'uppercase',
    lineHeight: '17px',
    opacity: props.disabled ? 0.5 : 1,
}));
const ExchangeWalletTitle = styled(ContainerColumn)();
const ExchangeWalletHeadline = ({ wallet, disabled = false, }) => (_jsxs(Container, { children: [_jsx(ExchangeIconStyled, { type: wallet.exchange.type, size: [46, 46], disabled: disabled }), _jsxs(ExchangeWalletTitle, { children: [_jsx(ExchangeName, { disabled: disabled, children: wallet.exchange.properties.connectionName }), _jsx(ExchangeType, { disabled: disabled, children: wallet.type })] })] }));
export default ExchangeWalletHeadline;
